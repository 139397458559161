<template>
  <div id="payeeManage">
    <div class="common-card-body">
      <div class="search-form ant-round-form">
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_pay_192') }}: </span>
          <a-select
            v-model:value="searchQuery.id"
            allow-clear
            show-search
            option-filter-prop="label"
            @change="loadPayeeList(1)"
          >
            <a-select-option
              v-for="item in accountNames"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            >{{ item.name }}</a-select-option>
          </a-select>
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_pay_193') }}: </span>
          <a-input
            v-model:value="searchQuery.acctNo"
            allow-clear
            @pressEnter="loadPayeeList(1)"
            @blur="loadPayeeList(1)"
          />
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_pay_194') }}: </span>
          <a-input
            v-model:value="searchQuery.mobile"
            allow-clear
            @pressEnter="loadPayeeList(1)"
            @blur="loadPayeeList(1)"
          />
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_pay_195') }}: </span>
          <a-select
            v-model:value="searchQuery.businessType"
            allow-clear
            @change="loadPayeeList(1)"
          >
            <a-select-option
              v-for="business in businessTypeList"
              :key="business.code"
              :value="business.code"
            >{{ business.name }}</a-select-option>
          </a-select>
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_pay_196') }}: </span>
          <a-select
            v-model:value="searchQuery.subjectType"
            allow-clear
            @change="loadPayeeList(1)"
          >
            <a-select-option
              v-for="item in subjectTypeList"
              :key="item.code"
              :value="item.code"
            >{{ item.name }}</a-select-option>
          </a-select>
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_pay_114') }}: </span>
          <a-select
            v-model:value="searchQuery.status"
            allow-clear
            @change="loadPayeeList(1)"
          >
            <a-select-option
              v-for="item in statusList"
              :key="item.code"
              :value="item.code"
            >{{ item.name }}</a-select-option>
          </a-select>
        </div>
      </div>
      <div class="table-part">
        <a-table
          :scroll="{ x: 1200 }"
          :loading="loading"
          :pagination="false"
          :custom-header-row="() => { return {class: 'my-table-tr'}}"
          :columns="columns"
          :data-source="payees"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'payeeName'">
              <img style="width: 17px; margin: -5px 5px 0 0" src="~@/assets/icons/ic-person.png">
              <span>{{ record.payeeName }}</span>
            </template>
            <template v-if="column.key === 'bankAddress'">
              <div class="flex-align">
                <img style="width: 21px; height: 13px" :src="getCountryLogo(record.currency)">
                <span style="margin-left: 5px">{{ record.country }}</span>
              </div>
            </template>
            <template v-if="column.key === 'subjectType'">
              <div class="flex-align">
                <div :class="record.subjectType === '00' ? 'circle-person' : 'circle-company'" />
                <span style="margin-left: 5px">{{ record.subjectTypeText }}</span>
              </div>
            </template>
            <template v-if="column.key === 'edit'">
              <a-space style="width: 100%; justify-content: center">
                <a-button
                  v-if="['02', '03'].includes(record.status)"
                  size="small"
                  type="link"
                  @click="updatePayee(record.id)"
                >{{ $t('pages_pay_199') }}</a-button>
                <a-divider v-if="['02', '03'].includes(record.status)" type="vertical" />
                <a-popconfirm v-if="record.status !== '00'" :title="$t('pages_pay_200')" @confirm="() => deletePayee(record.id)">
                  <a-button
                    size="small"
                    type="link"
                    danger
                  >{{ $t('pages_pay_201') }}</a-button>
                </a-popconfirm>
                <a-button
                  v-if="record.status === '01'"
                  shape="round"
                  size="small"
                  type="primary"
                  @click="toPay(record.id, record.businessType)"
                >{{ $t('pages_pay_191') }}</a-button>
              </a-space>
            </template>
          </template>
        </a-table>
        <table-page
          :total="total"
          :page-opts="searchQuery"
          @update-page="updatePage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, computed } from 'vue'
import TablePage from '@/components/table-page'
import { useRouter } from 'vue-router'
import { _pay } from '@/api'
import { getAttrStatusList, getTradeStatusName, getCountryLogo } from '@/util'
import { message } from 'ant-design-vue'
import i18n from '@/locale'
export default {
  name: 'PayeeManage',
  components: {
    'table-page': TablePage
  },
  setup () {
    const router = useRouter()
    const state = reactive({
      loading: false,
      exportLoading: false,
      searchQuery: {
        pageNum: 1,
        pageSize: 10,
        id: '',
        acctNo: '',
        mobile: '',
        subjectType: '',
        businessType: '',
        status: ''
      },
      total: 0,
      accountNames: [],
      payees: [],
      columns: [
        { key: 'payeeName', title: i18n.global.t('pages_pay_192'), width: 200, ellipsis: true },
        { key: 'businessTypeName', dataIndex: 'businessTypeName', title: i18n.global.t('pages_pay_195'), width: 200 },
        { key: 'payeeType', dataIndex: 'payeeTypeText', title: i18n.global.t('pages_pay_197'), width: 200 },
        { key: 'bankNum', dataIndex: 'bankNum', title: i18n.global.t('pages_pay_193'), width: 200 },
        { key: 'bankAddress', title: i18n.global.t('pages_pay_094'), width: localStorage.getItem('locale-language') === 'en' ? 220 : 180 },
        { key: 'branchName', dataIndex: 'branchName', title: i18n.global.t('pages_pay_143'), width: 200, ellipsis: true },
        { key: 'subjectType', dataIndex: 'subjectTypeText', title: i18n.global.t('pages_pay_089'), width: localStorage.getItem('locale-language') === 'en' ? 200 : 150 },
        { key: 'mobile', dataIndex: 'mobile', title: i18n.global.t('pages_pay_194'), width: 150 },
        { key: 'certNo', dataIndex: 'certNo', title: `${i18n.global.t('pages_pay_091')}/${i18n.global.t('pages_pay_092')}`, width: localStorage.getItem('locale-language') === 'en' ? 238 : 200 },
        { key: 'currency', dataIndex: 'currency', title: i18n.global.t('pages_pay_198'), width: localStorage.getItem('locale-language') === 'en' ? 180 : 120 },
        { key: 'status', dataIndex: 'statusText', title: i18n.global.t('pages_pay_114'), width: 120 },
        { key: 'edit', title: i18n.global.t('common_text_001'), align: 'center', fixed: 'right', width: 150 }
      ]
    })

    state.subjectTypeList = computed(() => {
      const opts = getAttrStatusList('subjectType')
      return opts.splice(0, 2)
    })

    state.businessTypeList = computed(() => {
      return getAttrStatusList('payeeBusinessType')
    })

    state.statusList = computed(() => {
      return getAttrStatusList('payeeStatus')
    })

    const updatePage = (page, pageSize) => {
      state.searchQuery.pageNum = page
      state.searchQuery.pageSize = pageSize
      loadPayeeList()
    }

    const updatePayee = (payeeId) => {
      const query = { payeeId }
      router.push({ path: '/funds/inbounds/payee_edit', query })
    }

    const deletePayee = (id) => {
      _pay.removePayee({ id })
        .then(res => {
          message.success(i18n.global.t('pages_pay_203'))
          loadPayeeList()
          loadPayeeNameList()
        })
        .catch(err => console.log(err))
    }

    const toPay = (payeeId, businessType) => {
      const query = { payeeId }
      if (businessType.indexOf('00') !== -1) query.businessType1 = '00'
      if (businessType.indexOf('01') !== -1) query.businessType2 = '01'
      router.push({ path: '/funds/inbounds/single_cny', query })
    }

    const exportPayee = async () => {
      state.exportLoading = true
      await _pay.exportPayee(state.searchQuery)
      state.exportLoading = false
    }

    const loadPayeeNameList = async () => {
      const res = await _pay.getPayeeNameList()
      if (res.data.succ) {
        state.accountNames = res.data.data
      }
    }

    const loadPayeeList = async (pageNum) => {
      state.loading = true
      if (pageNum) state.searchQuery.pageNum = pageNum
      const query = Object.assign({}, state.searchQuery, {
        pageNo: state.searchQuery.pageNum
      })
      delete query.pageNum
      const res = await _pay.getPayeeList(query)
      if (res.data.succ) {
        state.payees = res.data.data.list.map(item => {
          return Object.assign({}, item, {
            subjectTypeText: getTradeStatusName(item.subjectType, 'subjectType'),
            countryName: getTradeStatusName(item.country, 'country'),
            acctName: item.bankProvince ? item.bankProvince + '/' + item.bankCity : '-',
            payeeTypeText: getTradeStatusName(item.payeeType, item.subjectType === '00' ? 'personalPayeeType' : 'enterprisePayeeType'),
            statusText: getTradeStatusName(item.status, 'payeeStatus')
          })
        })
        state.total = res.data.data.total
      }
      state.loading = false
    }

    const init = () => {
      loadPayeeList()
      loadPayeeNameList()
    }

    return {
      init,
      exportPayee,
      updatePage,
      updatePayee,
      deletePayee,
      toPay,
      getCountryLogo,
      loadPayeeList,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#payeeManage {
  text-align: left;
  .common-card-body {
    margin-top: 0;
    padding: 0;
    .search-form {
      margin-top: 8px;
      .part-label {
        width: 150px;
      }
    }
    .table-part {
      margin-top: 12px;
      .circle-person, .circle-company {
        width: 6px;
        height: 6px;
        background: @success-color;
        margin-right: 8px;
        border-radius: 50%;
      }
      .circle-company {
        background: @main-color;
      }
    }
  }
}
</style>
