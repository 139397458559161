<template>
  <div id="payReviewer">
    <header-nav :content="`${$t('pages_router_007')} / ${ $t('pages_router_034')}`" />
    <div class="common-card-body">
      <a-tabs v-model:activeKey="activeKey" class="header-tabs">
        <template #leftExtra>
          <div class="flex-align">
            <div class="payee-header" @click="$router.push(activeKey === 'outbound' ? '/funds/outbounds' : '/funds/inbounds')">
              <left-outlined class="ic-back" />
              {{ $t('pages_pay_191') }}
            </div>
          </div>
        </template>
        <template #rightExtra>
          <a-button type="primary" style="min-width: 138px; margin-right: 18px" @click="$router.push(activeKey === 'outbound' ? '/funds/outbounds/payee_edit' : '/funds/inbounds/payee_edit')">
            <template #icon><plus-outlined /></template>
            {{ $t('pages_pay_006') }}
          </a-button>
          <a-button
            type="primary"
            ghost
            style="min-width: 100px"
            :loading="exportLoading"
            @click="handleExport"
          >
            <template #icon><download-outlined /></template>
            {{ exportLoading ? $t('common_text_023') : $t('common_text_033') }}
          </a-button>
        </template>
        <a-tab-pane key="outbound" :tab="$t('pages_pay_110')">
          <payee-outbounds ref="outboundRef" />
        </a-tab-pane>
        <a-tab-pane key="inbound" :tab="$t('pages_pay_111')">
          <payee-inbounds ref="inboundRef" />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import { ref, reactive, toRefs, nextTick, watch, computed } from 'vue'
import { useRoute } from 'vue-router'
import HeaderNav from '@/components/header-nav'
import PayeeOutbounds from './payee-outbounds'
import PayeeInbounds from './payee-inbounds'
import i18n from '@/locale'
import { getAttrStatusList } from '@/util'
export default {
  name: 'PayReviewer',
  components: {
    'header-nav': HeaderNav,
    'payee-outbounds': PayeeOutbounds,
    'payee-inbounds': PayeeInbounds
  },
  setup() {
    const route = useRoute()
    const state = reactive({
      activeKey: route.query.key || 'outbound',
      loading: false,
      exportLoading: false,
      searchQuery: {
        pageNum: 1,
        pageSize: 10,
        id: '',
        acctNo: '',
        mobile: '',
        subjectType: '',
        businessType: '',
        status: ''
      },
      total: 0,
      accountNames: [],
      payees: [],
      columns: [
        { key: 'payeeName', title: i18n.global.t('pages_pay_192'), width: 200, ellipsis: true },
        { key: 'bankNum', dataIndex: 'bankNum', title: i18n.global.t('pages_pay_193'), width: 200 },
        { key: 'bankAddress', title: i18n.global.t('pages_pay_094'), width: 180 },
        { key: 'branchName', dataIndex: 'branchName', title: i18n.global.t('pages_pay_143'), width: 200, ellipsis: true },
        { key: 'subjectType', dataIndex: 'subjectTypeText', title: i18n.global.t('pages_pay_089'), width: 150 },
        { key: 'mobile', dataIndex: 'mobile', title: i18n.global.t('pages_pay_194'), width: 150 },
        { key: 'certNo', dataIndex: 'certNo', title: `${i18n.global.t('pages_pay_091')}/${i18n.global.t('pages_pay_092')}`, width: 200 },
        { key: 'currency', dataIndex: 'currency', title: i18n.global.t('pages_pay_198'), width: 120 },
        { key: 'businessTypeName', dataIndex: 'businessTypeName', title: i18n.global.t('pages_pay_195'), width: 200 },
        { key: 'payeeType', dataIndex: 'payeeTypeText', title: i18n.global.t('pages_pay_197'), width: 200 },
        { key: 'status', dataIndex: 'statusText', title: i18n.global.t('pages_pay_114'), width: 120 },
        { key: 'edit', title: i18n.global.t('common_text_001'), align: 'center', fixed: 'right', width: 150 }
      ]
    })

    state.subjectTypeList = computed(() => {
      const opts = getAttrStatusList('subjectType')
      return opts.splice(0, 2)
    })

    state.businessTypeList = computed(() => {
      return getAttrStatusList('payeeBusinessType')
    })

    watch(() => state.activeKey,
      value => {
        nextTick(() => {
          if (value === 'inbound' && inboundRef.value) inboundRef.value.init()
          if (value === 'outbound' && outboundRef.value) outboundRef.value.init()
        })
      },
      { immediate: true }
    )

    const outboundRef = ref(null)
    const inboundRef = ref(null)

    const handleExport = async () => {
      state.exportLoading = true
      if (state.activeKey === 'outbound') await outboundRef.value.exportPayee()
      else await inboundRef.value.exportPayee()
      state.exportLoading = false
    }

    return {
      outboundRef,
      inboundRef,
      handleExport,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#payReviewer {
  .common-card-body {
    padding: 8px 0;
    .header-tabs:deep(.ant-tabs-nav) {
      padding: 0 18px;
      .payee-header {
        margin-right: 24px;
        font-weight: 500;
        cursor: pointer;
        .ic-back {
          color: @main-color;
        }
      }
    }
  }
}
</style>

